import React, { useEffect } from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import $ from "jquery";
import locales from "../../../constants";

const Unity = ({ pageContext: { slug } }) => {
  useEffect(() => {
    $(".glitchHoverEffect").hover(function () {
      $(".glitch").toggleClass("effectOn");
    });
  });
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #A08C55, #AE985B, #BBA360, #AE985B, #A08C55 )",
        icons: "#c5af71",
        navClass: "unity",
        ogImage: require("../../../assets/img/portfolio/unity_top_back.png"),
      }}
      seo={{
        title: "Unity Centre",
        headerTitle: "unity",
        href: slug,
        lang: "en",
        featuredImage: require("../../../assets/img/portfolio/unity_featured.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/unity-centre/",
      }}
      // langSwitch={{
      //   langKey: "en",
      //   langSlug: "/en/projects/rspwn/",
      // }}
    >
      <PortfolioHeader name="unity" height="300" />
      <section className="container-fluid unity_section_2" id="info">
        <div className="row">
          <div className="col-lg-6">
            <div className="inner">
              <h1>Unity Centre</h1>
              <ul>
                <li>Website</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner">
              <p>
                The GDK Group approached us with the ambitious task of creating
                a new website for the Unity Centre. The previous page did not
                meet our Client's business goals and downplayed the scale and
                momentum of the project. We approached this topic anew. Along
                with the change in the shareholding structure, we disenchanted
                the well known "Skeleton" that's been haunting Krakow for many
                years and turned it into the most modern business centre in
                Krakow - the Unity Centre.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_3">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <div className="inner">
              <h3>Website</h3>
              <p>
                The website was created based on the JamStack
                (GatsbyJS+WordPress) technology, and not in most popular
                technologies such as HTML, CSS or JavaScript. One of the key
                differences between these technologies is the speed of the
                website, which can be easily noticed in the first few seconds of
                using it. The way of experiencing and communing with the website
                is to give the impression of lightness, delicacy and
                intuitiveness, and all of that should take place among
                extraordinary aesthetics.{" "}
              </p>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/unity-website.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_4">
        <div className="row">
          <div className="col-md-5">
            <img
              src={require("../../../assets/img/portfolio/unity_img_1.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="inner">
              <p>
                We have created a completely new architecture for the content
                and information from scratch, presenting all the advantages of
                the new business centre. Additionally, we focused on
                highlighting the Art Déco style, which accompanies the entire
                investment project.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_5">
        <div className="row">
          <div className="col-md-7 offset-md-2">
            <img
              src={require("../../../assets/img/portfolio/unity_img_2.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_6">
        <div className="row">
          <div className="col-md-3 offset-md-5">
            <img
              src={require("../../../assets/img/portfolio/unity_img_4.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-4">
            <img
              src={require("../../../assets/img/portfolio/unity_img_3.png")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_7">
        <div className="row">
          <div className="col-md-3 offset-md-1">
            <img
              src={require("../../../assets/img/portfolio/unity_phone.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-5">
            <div className="inner">
              <p>
                "The skyscraper must be tall, every inch of it tall.
                <br />
                The force and power of altitude must be in <br /> it, the glory
                and pride of exaltation must be in it.
                <br /> It must be every inch a proud and soaring thing"
              </p>
              <p className="txt_01">
                Louis Sullivan, American architect, <br />
                1856-1924
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid unity_section_8">
        <div className="row">
          <div className="col-md-12"></div>
        </div>
      </section>

      <section className="container-fluid unity_section_9">
        <img
          src={require("../../../assets/img/portfolio/unity_img_full.png")}
          alt=""
          className="img-fluid"
        />
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Unity;
